import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);



const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/product',
    component: ()=>import("@/views/product/index")
  },
  {
    path: '/product/:type',
    component: ()=>import("@/views/product/index")
  },
  {
    path: '/about',
    component: ()=>import("@/views/about")
  },
  {
    path: '/join',
    component: ()=>import("@/views/join")
  },
  {
    path: '/contact',
    component: ()=>import("@/views/contact")
  },
  {
    path: '/news',
    component: ()=>import("@/views/news")
  },
  {
    path: '/news/detail',
    name: 'newsDetail',
    component: ()=>import("@/views/news/temp")
  }
];

const router = new VueRouter({
  routes,
});

router.afterEach((to,from,next) => {
  document.querySelector("#app").scrollTo(0,0);
});

export default router;
