<template>
  <div class="container">
    <div class="header">
      <div class="header-body">
        <router-link to="/" tag="img" src="/img/weilanlogo.png" class="logo" />
        <ul class="hidden-sm-and-down menus">
          <li
            v-for="(item, index) in menus"
            :key="index"
            @click="handleCommand(index)"
          >{{ item.name }}</li>
        </ul>
        <el-dropdown trigger="click" class="sm-menus hidden-md-and-up" @command="handleCommand">
          <i class="el-icon-menu"></i>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(item, index) in menus" :key="index" :command="index">
              {{
              item.name
              }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>

    <div class="header-bg"></div>
    <slot></slot>
    <div class="footer">
      <div class="footer-menus">
        <div class="box">
          <el-row>
            <el-col :xs="24" :sm="24" :md="4" :lg="4" :xl="4">
              <div class="box-item">
                <img src="/img/weilanlogo.png" class="logo" alt />
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="2" :lg="2" :xl="2">
              <div style="height:1px"></div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10">
              <div class="box-item">
                <p class="t1">产品介绍</p>
                <el-row>
                  <el-col :span="12">
                    <router-link to="/product/hs" tag="p" class="t2">可回收智能投递箱（柜）</router-link>
                    <router-link to="/product/jwb" tag="p" class="t2">巨无霸-全品类智能回收站</router-link>
                    <router-link to="/product/dt" tag="p" class="t2">智能单体投瓶机</router-link>
                    <router-link to="/product/fd" tag="p" class="t2">智能发袋机</router-link>
                  </el-col>
                  <el-col :span="12">
                    <router-link to="/product/gb" tag="p" class="t2">智能过磅机</router-link>
                    <router-link to="/product/cy" tag="p" class="t2">厨余/其他垃圾智能(AI)投递箱（柜）</router-link>
                  </el-col>
                </el-row>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4">
              <div class="box-item">
                <p class="t1">关于我们</p>
                <router-link to="/about" tag="p" class="t2">公司简介</router-link>
                <router-link to="/join" tag="p" class="t2">人才招聘</router-link>
              </div>
            </el-col>
            <el-col :xs="12" :sm="12" :md="4" :lg="4" :xl="4">
              <div class="box-item">
                <p class="t1">联系我们</p>
                <router-link to="/contact" tag="p" class="t2">联系方式</router-link>
              </div>
            </el-col>
          </el-row>
          <div style="height:6rem"></div>
        </div>
      </div>
      <div class="footer-address">
        <el-row>
          <el-col :sm="12" :md="12" :lg="8">
            <span style="cursor: pointer;" @click="jump('https://beian.miit.gov.cn/')">版权所有：宁波蔚澜环保科技有限公司 浙ICP备19023937号-1</span>
          </el-col>
          <el-col :sm="12" :md="12" :lg="5">地址：浙江省鄞州区明曙路810号</el-col>
          <el-col :sm="12" :md="12" :lg="6">联系电话：400 0574 168（周一至周日 8:30-22:00）</el-col>
          <el-col :sm="12" :md="12" :lg="5">合作邮箱：hr@weilanep.cn</el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false,
      menus: [
        {
          name: "首页",
          url: "/",
        },
        {
          name: "产品介绍",
          url: "/product",
        },
        {
          name: "新闻动态",
          url: "/news",
        },
        {
          name: "公司简介",
          url: "/about",
        },
        {
          name: "人才招聘",
          url: "/join",
        },
        {
          name: "联系我们",
          url: "/contact",
        },
      ],
    };
  },
  methods: {
    handleCommand(key) {
      this.$router.push({ path: this.menus[key].url });
    },
    jump(url) {
      console.log(1211);
      window.location.href = url;
    },
  },
};
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  position: relative;
}
.header {
  padding: 3.1vw 0;
  position: absolute;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  .header-body {
    max-width: 1520px;
    width: 80%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .logo {
    height: 2vw;
    min-height: 30px;
    cursor: pointer;
  }
  .menus {
    list-style: none;
    li {
      margin-left: 8rem;
      display: inline-block;
      font-family: PingFangSC;
      font-size: 2rem;
      line-height: 2.3rem;
      letter-spacing: 1px;
      color: #ffffff;
      opacity: 0.6;
      cursor: pointer;
      &:hover {
        opacity: 1;
      }
    }
  }
  .sm-menus {
    font-size: 6vw;
    color: #fff;
  }
}
.header-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 27rem;
  background: linear-gradient(
    180deg,
    #000922 0%,
    rgba(0, 9, 34, 0.261034) 62.61%,
    rgba(0, 9, 34, 0.0001) 100%
  );
  mix-blend-mode: normal;
  opacity: 0.9;
  z-index: 1;
}

.footer {
  position: relative;
  z-index: 99;
  .footer-menus {
    background: #1a233c;
    color: #ffffff;
    .logo {
      display: block;
      margin: 6.8rem auto;
    }
    .box {
      max-width: 1620px;
      width: 100%;
      margin: 0 auto;
      .box-item {
        padding: 0 20px;
      }
    }
    .t1 {
      font-size: 3rem;
      line-height: 3.5rem;
      letter-spacing: 0.75px;
      color: #ffffff;
      margin: 6.8rem 0 3rem;
    }
    .t2 {
      font-size: 2rem;
      line-height: 4rem;
      letter-spacing: 1px;
      color: #ffffff;
      mix-blend-mode: normal;
      opacity: 0.7;
      margin-bottom: 1.4rem;
      cursor: pointer;
      max-width: 22rem;
    }
  }
  .footer-address {
    background: #121828;
    padding: 2.1rem;
    font-size: 1.4rem;
    line-height: 4rem;
    letter-spacing: 0.7px;
    color: #ffffff;
    text-align: center;
  }
}
</style>
